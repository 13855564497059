.collapsable_item{
    margin-bottom: 2rem;
    border-bottom: 1px solid  rgba(0, 0, 0, 0.3);;
}


.collapse_text_title{
    font-size: 1.3rem;
    font-weight: normal;
    margin-bottom: 1.5rem;
}

.collapse_text_title:hover{
    cursor: pointer;
}

.collapse_text_title i{
    float: right;
}

.collapse_text_body p {
    line-height: 1.8;
    transition: height 0.2s ease-in-out;

} 

@media (max-width: 576px) { 

    .collapse_text_title{
        font-size: 1rem;
        font-weight: normal;
        margin-bottom: 1.2rem;
    }

    .collapse_text_body p {
        font-size: 0.9rem;
        line-height: 1.4;
        transition: height 0.2s ease-in-out;
    
    } 

}

@media (min-width: 771px) and (max-width: 900px) {

    .collapse_text_title{
        font-size: 1rem;
        font-weight: normal;
        margin-bottom: 1.2rem;
    }

    .collapse_text_body p {
        font-size: 0.9rem;
        line-height: 1.4;
        transition: height 0.2s ease-in-out;
    
    } 

}