.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }

.nav_container{
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    padding-left: 4rem;
    padding-right: 4rem;
    overflow: hidden;
    width: 100%;
}


/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){

  .nav_container{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}


