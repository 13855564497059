.search_row{
    background: #f1f3f5;
    padding: 4rem;
}

.search_container{
    display: flex;
    position: relative;
}

.btn_search{
    cursor: pointer;
    background-color: rgb(239, 90, 115);
    color: #fff;
    min-height: 0;
    border-radius: 0.2rem;
    display: inline-block;
    padding: 0.5rem 1rem;
    overflow: visible;
    max-height: 3rem;
    border: none;
    height: 100%;
}

.hero_profile{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    padding-bottom: 2rem;
}

.hero_profile_content{
    padding-left: 1.5rem;
    padding-right: 1.5rem;

}

.hero_profile_picture{
    width: 100%;
    height: auto;
    max-width: 25em;
}

.hero_profile_header{
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    
}

.hero_business_name{
    font-size: 1.3rem;
    font-weight: 700;
}

.hero_dropdown_icon{
    text-align: center;
    font-size: 2rem;
    
}

.hero_dropdown_icon i{
    cursor: pointer;

}

.profile_break_line{
    border: 1px solid #000;
}

.location_text{
    padding-left: 1rem;
}

.hero_location{
    font-size: 1.2rem;

}

.hero_job_hires i {
    font-size: 1.2rem;
}

.hero_job_count{
    padding-left: 1rem;
}

.hero_profile_footer{
    text-align: center;
    margin-top: 2rem;
}

.hero_profile_footer h6{
    margin-bottom: 2rem;
}

.hero_get_qoute{
   
    text-decoration: none;
    color: #000;
}

.btn_view_hero{
    border-radius: 2rem;
    border: 1px solid rgb(239, 90, 115);
    background: #FFF; 
    color: #000;
    text-decoration: none;
    padding: 1rem;
    width: max-content;
    
}

.search_results_text{
    text-align: center;
    font-size: 1rem;
}

/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){
    .search_row{
        background: #FFF;
        padding: 0rem;
    }
}