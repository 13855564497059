.container{
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.logo_container{
    padding-left: 2rem;
}


.logo{
    padding-left: 1rem;
}

.nav_menu{
    padding-top: 0.5rem;
    display: flex;
    justify-self: end;
}

.nav_menu ul .nav_menu_item{
    display: inline;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.nav_menu ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}


.nav_menu ul li a {
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.btn_become_hero{
    border-radius: 2rem;
    background: rgb(239,90,115);
    border: rgb(239,90,115) 1px solid;
    padding: 0.5em;
    color: rgb(255, 255, 255) !important;
}

.btn_become_hero:hover{
    background: rgb(255, 255, 255);
    color: rgb(239,90,115) !important;
}

/**
For Mobile Devvices
*/
@media only screen and (max-width: 65em) {
    .container{
        display: none;
    }
}

