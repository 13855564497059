@import url('https://fonts.googleapis.com/css?family=Nunito');


body {
  margin: 0;
  font-family:'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
  background-color: #F5F5F6;
  padding: 0;
  color: #2f3033;
}

header{
  margin-top: 10em;
}



input:focus, input.form-control:focus {

  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
