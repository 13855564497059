
.mobile_header{
    display: none;
}

.header{
    background-image: url('../../assets/images/jpg/woman_home_maintenance.jpg');
    width: 90%;
    height: 60em;
    background-repeat: no-repeat;
    border-radius: 3em;
    margin-left: 4.5rem;
    margin-top: 10em;
    background-size: cover;
}

.header_h1{
    font-size: 4rem;
    line-height: normal;
    font-weight: 900;
    color: #2f3033;
    position: relative;
    top: 5em;
    width: 50%;
    left: 1.5em;
}

.btn_get_started{
    border-radius: 2rem;
    background-color: rgb(255,255,255);
    font-size: 1.8rem;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: rgb(239, 90, 115);;
    padding: 0.9rem 4rem 0.9rem 4rem;
    text-decoration: none;
    position: relative;
    left: 3.2em;
    top: 12em;

}

.btn_get_started:hover{
    border: 1px solid rgb(255,255,255);
    background-color: rgb(239, 90, 115);;
    color: rgb(255,255,255);

}



.service_icons{
    color: rgb(239, 90, 115);;
    font-size: 1rem;
    margin-top: 24rem; 
    margin-left: 5rem;
    display: flex;
}

.service_icons_col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
}

.service_icon i{
    font-size: 1.8rem;

}

section {
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.section_title{
    font-size: 1.6rem;
    font-weight: 700;
    justify-content: center;
    display: flex;
    margin-bottom: 4rem;
  
}

.features_card{
    border-radius: 20px;
    background: rgb(255,255,255);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem;
    height: calc(100% - 10px);
}

.features_card:hover{
    background: rgb(239, 90, 115);
    transform: scale(1.03);
    color: rgb(255,255,255);
}



.features_card_icon{
    border: 1px solid rgb(239, 90, 115);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
    display: flex;
 
}


.features_card_icon i {
    font-size: 1.5rem;
    color: rgb(239, 90, 115);;
    padding: 0
}

.features_card_icon:hover{
    border: 1px solid rgb(255,255,255);

}

.features_card_icon i:hover{
    color: rgb(255,255,255);
}

.features_card_title{
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.features_card_text{
    line-height: 1.8;
}

#rec_shape_blue{
    border-radius: 3.5em;
    background: #66BCCB; 
    width: 8em;
    height: 32em; 
}

.plumber_img{
    width: 25em;
    border-radius: 2rem;
    height: 22em;
    position: relative;
    bottom: 30rem;
    left: 2rem;
}

.faq_section{
    background: rgb(255,255,255);
    height: 680px;
}

#rec_shape_yellow{
    border-radius: 3.5em;
    background: #F0B51E;
    width: 8em;
    height: 30em;
    position: relative;
    left: 54rem;
    bottom: 16rem;

}

.header_join_pro_network{
    color: rgb(239, 90, 115);;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
}

.img_join_pro_network{
    width: 30em;
    border-radius: 2rem;
    height: 25em;
}

.text_content_join_pro_network{
    padding-top: 9rem;
    text-align: left;
    padding-left: 4rem !important;
}

.btn_join_pro_network{
    background: rgb(239, 90, 115);;
    padding: 0.5em;
    color: rgb(255,255,255) !important;
    text-decoration: none;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.2rem;
    border-radius: 2rem; 
    position: relative;


}

.handyman_image{
    width: 30em;
    border-radius: 2rem;
    height: 25em;
    position: relative;
    bottom: 47rem;
    left: 30rem;

}

.create_service_request_btn_link{
    background: rgb(239, 90, 115);
    padding: 0.5em;
    color: rgb(255,255,255) !important;
    text-decoration: none;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.2rem;
    border-radius: 2rem; 
    position: relative;
    top: 5rem;
}

.create_service_request_btn_link:hover{
    background: rgb(255,255,255);
    color: rgb(239, 90, 115) !important;
    border: rgb(239, 90, 115) 1px solid;

}

.join_text{
    color: rgb(239, 90, 115);;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
    width: 35%;
}


#telephone_icon{
    font-size: 6rem;
    position: relative;
    top: 1rem
}

.contact_call_to_action{
    background: #66BCCB;
    display: flex;
    justify-content: center;

}

.contact_call_to_action h6{
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 2rem;
  
} 

.contact_call_to_action p{
    margin-top: 1rem;
  
} 

.contact_call_to_action #phone_number{
    color: rgb(239, 90, 115);;
}

.d_flex_center{
    display: flex;
    justify-content: center;
}

.section_cta{
    height: 30em;
    margin-top: 2rem;
}

.cta_content{
    width: 100%;

}

.cta_image{
    width: 50%;
}

.section_contact{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-bottom: 5rem;
    margin-top: 2rem;
}

/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){
    .header{
        display: none;
    }

    .mobile_header{
        display: block;
        background-image: url('../../assets/images/png/warm-blue-1.png');
        width: 100%;
        height: 30em;
        background-repeat: no-repeat;
        border-radius:  0 0 1em 1em;
        margin-left: 0;
        margin-top: 0;
        background-size: cover;
        padding-top: 8rem;
        text-align: center;
    }

    .service_icons{
        color: rgb(255,255,255);
        font-size: 0.5rem;
        margin-top: 1rem;
        margin-left: 0rem;
        justify-content: center;
        display: flex;
    }

    .header_h1{
        font-size: 2.5rem;
        line-height: normal;
        font-weight: 900;
        position: relative;
        top: 0em;
        width: 100%;
        left: 0;
        text-align: center;
    }

    .mobile_header .sub_text{
        margin: 0;
        padding: 1rem;
        text-align: center;
        overflow: hidden;
        margin-bottom: 2rem;
    }

    .btn_get_started{
        border-radius: 2rem;
        background-color: rgb(255,255,255);
        font-size: 1.3rem;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        color: rgb(239, 90, 115);
        padding: 0.9rem 4rem 0.9rem 4rem;
        text-decoration: none;
        position: inherit;
        left: 0;
        top: 0;
    }

    .section_title {
        font-size: 1.2rem;
        font-weight: 700;
        justify-content: center;
        display: flex;
        width: 100%;
        margin-top: 4rem;
        margin-bottom: 2rem;
    }

    .plumber_img{
        display: none;
    }

    .section_cta{
        display: none;
    }
    #rec_shape_blue{
        display: none;
    }

    #telephone_icon{
        display: none;
    }

    .contact_call_to_action{
        text-align: center;
    }

    .section_contact{
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin-top: 0rem;
    }

    .faq_section{
        background: rgb(255,255,255);
        height: 610px;
    }

    .img_join_pro_network{
        width: 100%;
        border-radius: 2rem;
        height: auto;
        max-width: 45em;
    }

    .header_join_pro_network{
        font-size: 1.5rem;
        
    }
    
    .text_content_join_pro_network p{
        padding-bottom: 2rem;

    }

    .text_content_join_pro_network{
        padding-top: 0;
        text-align: center;
        padding-left: 0rem !important;
    }

}






/**
* For Tablets
*/
@media (max-width: 990px) {

    .header{
        display: none;
    }

    section{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .mobile_header{
        display: block;
        background-image: url('../../assets/images/png/warm-blue-1.png');
        width: 100%;
        height: 35em;
        background-repeat: no-repeat;
        border-radius:  0 0 1em 1em;
        margin-left: 0;
        margin-top: 0;
        background-size: cover;
        padding-top: 8rem;
        text-align: center;
    } 

    .service_icons{
        color: rgb(255,255,255);
        font-size: 0.65rem;
        margin-top: 2rem;
        margin-left: 0rem;
        justify-content: center;
        display: flex;
    }

    .header_h1{
        font-size: 3rem;
        line-height: normal;
        font-weight: 900;
        color: #2f3033;
        position: relative;
        top: 0em;
        width: 100%;
        left: 0;
        text-align: center;
    }

    .mobile_header .sub_text{
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;
        overflow: hidden;
        margin-bottom: 2rem;
    }

    .btn_get_started{
        border-radius: 2rem;
        background-color: rgb(255,255,255);
        font-size: 1.3rem;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        color: rgb(239, 90, 115);;
        padding: 0.9rem 4rem 0.9rem 4rem;
        text-decoration: none;
        position: inherit;
        left: 0;
        top: 0;
    }

    .faq_section{
        background: rgb(255,255,255);
        height: 560px;
    }

    
    .plumber_img{
        display: none;
    }

    .section_contact{
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    .section_cta{
        display: none;
    }
    #rec_shape_blue{
        display: none;
    }

    #telephone_icon{
        display: none;
    }

    .contact_call_to_action{
        text-align: center;
    }

    .img_join_pro_network{
        width: 100%;
        border-radius: 2rem;
        height: auto;
        max-width: 55em;
    }

    .header_join_pro_network{
        font-size: 1.5rem;
        
    }

    .text_content_join_pro_network p{
        padding-bottom: 2rem;

    }

    .text_content_join_pro_network{
        padding-top: 0rem;
        text-align: center;
        padding-left: 0rem !important;
    }

} 

/** 
* For Smaller Laptop Devices
/* css for a device in landscape with at least 1024px 
*/
@media (min-width: 994px) and (max-width: 1262px) {
    section {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .header{
        margin-left: 3rem;
    }

    .header_h1{
        font-size: 3.65rem;
    }

    .text_content_join_pro_network{
        padding-top: 7rem;
        text-align: left;
        padding-left: 8rem !important;
    }

    .plumber_img{
        width: 20em;
        border-radius: 2rem;
        height: 20em;
        position: relative;
        bottom: 30rem;
        left: 4rem;
    }

    .handyman_image{
        width: 25em;
        border-radius: 2rem;
        height: 22em;
        position: relative;
        bottom: 47rem;
        left: 25rem;
    
    }

    #rec_shape_yellow{
        border-radius: 3.5em;
        background: #F0B51E;
        width: 8em;
        height: 30em;
        position: relative;
        left: 44rem;
        bottom: 16rem;
    
    }
    
}