.footer{
    padding: 5rem;
    background: rgb(239, 90, 115);
}

.justify_content_between{
    display: flex;
    justify-content: space-between;
}

.footer_ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #fff;
}

.footer_ul li{
    font-size: 1rem;
    padding-bottom: 1rem;
}

.footer_ul li a{
    color: rgb(255,255,255);
    text-decoration: none;
}

.footer_affliation_img{
    width: 20em;
    height: 10em;
}

@media (max-width: 576px) {
    .footer{
        padding: 1rem;
    }

    .footer_affliation_img{
        width: 16em;
        height: 8em;
    }

}

/**
* For Tablets
*/
@media (min-width: 771px) and (max-width: 1292px) {
    .footer{
        padding: 2rem;
    }

    .footer_affliation_img{
        width: 18em;
        height: 9em;
    }

}