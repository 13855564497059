.autocomplete_container{
    width: inherit;
}

.search_input{
    width: inherit;
    padding: 0.5rem .75rem;
    font-family: inherit;
    border-radius: 0.3rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    font-weight: bold;
}

.search_input:focus-visible{
    outline: none;
}

.suggestions_container{
    width: inherit;
    margin: 0;
    padding: 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.suggestion{
    color: black;
    cursor: pointer;
    width: inherit;
    font-size: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 0.5rem;
    border-bottom: 1px solid #dedede;

}

.suggestion:hover{
    background-color: #dedede;

}