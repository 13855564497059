.select{
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-top: 20px;
    cursor: pointer;
    font-size: 1.3rem;
    padding: 1rem .75rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.3rem;
       
} 

@media (max-width: 576px) { 
    .select{
        font-size: 1rem;
        padding-top: 10px;
    } 
}

@media (min-width: 771px) and (max-width: 900px) {

    .select{
        font-size: 1rem;
        padding-top: 18px;
    } 

}