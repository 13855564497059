/* multi-select-dropdown.css */

.multi_select_input {
    position: relative;
    display: inline-block;
    width: 100%;
    
  }
  
.multi_select_input input{
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.3rem;
  padding: 1rem .75rem;

}

  
  .option {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6;
  }
  
  .option:last-child {
    border-bottom: none;
  }
  
  .option:hover {
    background-color: #f0f0f0;
  }
  
  
  .selected_options_container{
    display: inline-block;
    display: flex;
    margin-top: 1rem;
 
  }

  .selected_option{
    background-color: #E8635B;
    width: max-content;
    padding: 0.5rem;
    color: #fff;
    padding: 1rem;
    margin-left: 1rem;

  }

  .selected_option i{
    cursor: pointer;
  }

  