.btn_return{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 2rem;
    border: 1px solid #fff;
    background-color: rgb(239, 90, 115);
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    color: #fff;
    padding: 0.9rem 1rem 0.9rem 1rem;
    text-decoration: none;
}

.h_6{
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.paragraph{
    margin-top: 2rem;
}

.smiling_woman_with_phone{
    width: 40em;
    height: 30em;
}

.thank_you_message{
    padding-top: 8rem;
}

/**
For Mobile Devvices
*/
@media (max-width: 576px) {
    .smiling_woman_with_phone{
        width: 24em;
        height: 18em;
    }

    .thank_you_message{
        padding-top: 2rem;
        text-align: center;
    }

}

/**
* For Tablets
*/
@media (min-width: 771px) and (max-width: 1292px) {
    .smiling_woman_with_phone{
        width: 20em;
        height: 15em;
    }

    .thank_you_message{
        padding-top: 2rem;
        text-align: center;
    }

}