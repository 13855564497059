.header_text{
    text-align: center;
    font-size: 4rem;
    padding-top: 2rem;
    font-weight: 700;
}

.sub_text{
    text-align: center;
    font-size: 1.5rem;
    padding-top: 2rem;
}