.header_text{
    text-align: center;
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
    font-weight: 700;
}

.form{
    background: rgb(255,255,255);
}

.sub_text{
    text-align: center;
    font-size: 1.5rem;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.btn_submit{
    border-radius: 2rem;
    background: rgb(255,255,255);;
    border: rgb(239, 90, 115) 1px solid;
    padding-right: 1.5em;
    padding-left: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: rgb(239, 90, 115) !important;
    font-size: 1.3rem;
}

.d_flex_center{
    display: flex;
    justify-content: center;
}

.step_title{
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
}


.step_indicator{
    font-size: 3rem;
}

.d_flex_space_between{
    display: flex;
    justify-content: space-between;
}

.error_message p{
    color: red;
}

.section_contact{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}


#telephone_icon{
    font-size: 6rem;
    position: relative;
    top: 1rem
}

.contact_call_to_action{
    background: rgb(92, 174, 193);;
    display: flex;
    justify-content: center;

}

.contact_call_to_action h6{
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 2rem;
  
} 


.contact_call_to_action p{
    margin-top: 1rem;
  
} 

.contact_call_to_action #phone_number{
    color: rgb(239, 90, 115);
}

.text_center {
    text-align: center;
}

.header_h2{
    font-weight: 2rem;
    font-weight: 700;
}

.header_h4{
    font-size: 1.6rem;
    font-weight: 600;
}

.how_it_works_icon{
    font-size: 3rem;
    color: rgb(239, 90, 115);
}

.vector_container{
    justify-content: center;
    display: flex;
}

.vector_container img{
    margin-left: auto;
    margin-right: auto;
} 

.vector_relax_img {
    width: 30em;
    height: 30em;
}


/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){
    .vector_relax_img {
        width: 15em;
        height: 15em;
    }

}

/**
For Mobile Devvices
*/
@media (max-width: 776px) {


    .btn_submit{
        font-size: 1rem;
    }

    .section_contact{
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    #telephone_icon{
        display: none;
    }

    .contact_call_to_action{
        text-align: center;
    }
   
}