.container{
    display: none;
}

.btn_toggle{
    float: inline-end;
    font-size: 1.5rem;
    padding: 0;
    border: none;
}

.btn_toggle i {
    color: rgb(239, 90, 115);
    background: #fff;
    border: none;
}

.btn_toggle i::after{
    border: none;
} 



.menu{
    list-style-type: none;
    z-index: 1000;
    position: absolute;
    width: 100%;
    margin-top: 1rem;
    background: rgb(239, 90, 115);
    padding: 0;
    top: 4rem;
}


.menu li{
    padding: 16px 12px;
    border-bottom: rgb(233, 236, 237) 1px solid;
}

.menu li:hover{
    background: rgb(239,90,115);
    cursor: pointer;
} 

.menu a{
    text-decoration: none;
    color: rgb(47, 48, 51);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin: 0;
    padding: 0;
}

/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){
  
    .container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .menu_item a {
        text-decoration: none;
        color: rgb(255, 255, 255);
    }

    h4 a {
        text-decoration: none;
        color: rgb(239,90,115);
    }

    .logo{
        padding-top: 0.9rem;
    }
}

/**
* For Tablets
*/
@media (min-width: 802px) {
    .container{
        display: none;
    }
}
