

.header_text{
    text-align: center;
    font-size: 4rem;
    padding-top: 2rem;
    font-weight: 700;
}

.sub_text{
    text-align: center;
    font-size: 1.5rem;
    padding-top: 2rem;
}

.btn_start_project{
    display: flex;
    justify-content: center;
}

.btn_start_project a{
    margin-top: 2rem;
    border-radius: 2rem;
    border: 1px solid #fff;
    background-color: rgb(239, 90, 115);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: normal;
    color: #fff;
    padding: 0.9rem 1rem 0.9rem 1rem;
    text-decoration: none;
}

.btn_start_project a:hover{
    background-color: #fff;
    color: rgb(239, 90, 115);
    border: 1px solid rgb(239, 90, 115);
}

.vector_container img{
    margin-left: auto;
    margin-right: auto;
}

.step_container{
    padding: 10rem 0;
}

.step_container h6{
    color: rgb(239, 90, 115);
    font-weight: bold;
}

.vector_img{
    width: 30em;
    height: 30em;
}


/**
For Mobile Devvices
*/
@media only screen and (max-width: 50em){

    .header_text{
        text-align: center;
        font-size: 3rem;
        padding-top: 2rem;
        font-weight: 700;
    }

    .sub_text{
        text-align: center;
        font-size: 1.3rem;
        padding-top: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .vector_img{
        width: 100%;
        height: auto;
        max-width: 20em;
    }

    
    .step_container{
        padding: 0rem 0;
    }

}

@media (min-width: 802px) {

    .header_text{
        text-align: center;
        font-size: 3rem;
        padding-top: 2rem;
        font-weight: 700;
    }

    .vector_img{
        width: 100%;
        height: auto;
        max-width: 25em;
    }

    

    .step_container{
        padding: 6rem 3rem;
    }

}

/** 
* For Smaller Laptop Devices
/* css for a device in landscape with at least 1024px 
*/
@media (min-width: 994px) and (max-width: 1262px) {

    .step_container{
        padding: 10rem 2rem;
    }
}