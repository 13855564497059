/* Add these styles to your existing CSS file */

.progress {
    margin-top: 2rem;
    height: 0.2rem;
    background-color: #e0e0e0;
    border-radius: 2rem;
    overflow: hidden;
}

.progressBar {
    height: 100%;
    background-color: #e8635b;; /* Green color */
}
