.header_text{
    text-align: center;
    font-size: 3.5rem;
    margin: 0;
    padding: 0;
    font-weight: 700;
}

.sub_text{
    text-align: center;
    font-size: 1.5rem;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}


.btn_submit{
    border-radius: 2rem;
    background: #fff;
    border: rgb(239, 90, 115) 1px solid;
    padding-right: 1.5em;
    padding-left: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: rgb(239, 90, 115) !important;
    font-size: 1.3rem;
}

.step_title{
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
}


.d_flex_center{
    display: flex;
    justify-content: center;
} 

.d_flex_space_between{
    display: flex;
    justify-content: space-between;
}

.error_message p{
    color: red;
}


.section_contact{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}


#telephone_icon{
    font-size: 6rem;
    position: relative;
    top: 1rem
}

.contact_call_to_action{
    background: rgb(92, 174, 193);
    display: flex;
    justify-content: center;

}

.contact_call_to_action h6{
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 2rem;
  
} 


.contact_call_to_action p{
    margin-top: 1rem;
  
} 

.contact_call_to_action #phone_number{
    color: rgb(239, 90, 115);
}

.header_h2{
    font-weight: 2rem;
    font-weight: 700;
}

.handyman_value_proposition_icon{
    font-size: 3rem;
    color: rgb(239, 90, 115);
}

.text_center {
    text-align: center;
}

/**
For Mobile Devvices
*/
@media (max-width: 776px) {

    .btn_submit{
        font-size: 1.2rem;
    }

    .section_contact{
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    #telephone_icon{
        display: none;
    }

    .contact_call_to_action{
        text-align: center;
    }
   
}