
.menu_title{
    font-size: inherit;
    cursor: pointer;

}

.dropdown_menu{
    padding: 2rem;
    display: inline-grid;
    position: absolute;
    z-index: 1000;
    top:4rem;
    left:auto;
    right: auto;
    text-align: left;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    width: max-content;
}

.menu_item li{
    padding: 0;
    margin: 0;
}

.dropdown_menu li { 
    font-size: 1rem;
    padding: 0.75rem;
    clear: both;
    white-space: nowrap;
}

.dropdown_menu li:hover{
    background-color: rgba(0, 0, 0, 0.25);
}

.dropdown_menu .menu_item a{
    font-size: 0.8rem;
    cursor: pointer;
}